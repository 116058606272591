import styled from 'styled-components';

export const Container = styled.header`
  background: var(--yellow);
`;

export const Content = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  padding: 2rem 1.3rem 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 720px) {
    font-size: 87.5%;
    justify-content: center;
    flex-direction: column;
  }

  button {
    font-size: 1rem;
    background: var(--light-yellow);
    border: 0;
    padding: 0 2rem;
    border-radius: 0.25rem;
    height: 3rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;
