import React from 'react';
import ReactLoading from 'react-loading';
import { Switch, Route } from 'react-router-dom';
import Results from '../Pages/Results';

const Home = React.lazy(() => import('../Pages/Home'));
const Survey = React.lazy(() => import('../Pages/Survey'));
const Result = React.lazy(() => import('../Pages/Result'));

export const Routes = () => (
  <Switch>
    <React.Suspense
      fallback={
        <div
          style={{
            marginTop: '5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ReactLoading type="spin" color="#ffb81c" height="5%" width="5%" />
        </div>
      }
    >
      <Route path="/" exact component={Home} />
      <Route path="/survey/:id" exact component={Survey} />
      <Route path="/result" exact component={Result} />
      <Route path="/results/:id" exact component={Results} />
    </React.Suspense>
  </Switch>
);
