/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';

const production = 'survey.jbs.cam.ac.uk';
const dev = `cjbs-webapp-development-reports.azurewebsites.net`;
const localhost = 'localhost';

function findPoint(url: any): any {
  if (url === dev || url === localhost) {
    return 'https://cjbs-webapp-cjbs-reports-api-development.azurewebsites.net/api/';
  }
  if (url === production) {
    return 'https://cjbs-webapp-cjbs-reports-api.azurewebsites.net/api/';
  }
  return 'none';
}

export const api = axios.create({
  baseURL: findPoint(window.location.hostname),
});
