import React from 'react';
import AzureAD from 'react-aad-msal';
import ReactDOM from 'react-dom';
import { App } from './App';
import { authProvider } from './services/Auth/authProvider';

ReactDOM.render(
  <AzureAD provider={authProvider} forceLogin>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </AzureAD>,
  document.getElementById('root'),
);
