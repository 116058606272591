import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import {
  BiMenu,
  // BiHistory,
  BiLogOutCircle,
  BiUserCircle,
} from 'react-icons/bi';
import logoImg from '../../assets/judge-logo-small.png';

import { authProvider } from '../../services/Auth/authProvider';
import { Container, Content } from './styles';

import './index.css';
import './slide.css';

export function Header() {
  const name = authProvider.getAccountInfo()?.account.name;

  return (
    <>
      <Container>
        <Content>
          <a href="/">
            <img src={logoImg} alt="Cambridge Judge Business School logo." />
          </a>

          <div>
            <Menu
              menuButton={
                <MenuButton>
                  <BiMenu style={{ marginRight: '5px' }} />
                  Menu
                </MenuButton>
              }
            >
              <MenuItem>
                <BiUserCircle style={{ marginRight: '5px' }} />
                {name}
              </MenuItem>

              <MenuItem onClick={authProvider.logout}>
                <BiLogOutCircle style={{ marginRight: '5px' }} />
                Logout
              </MenuItem>
            </Menu>
          </div>
        </Content>
      </Container>
    </>
  );
}
