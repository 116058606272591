import styled from 'styled-components';

export const Container = styled.main`
  max-width: 1120px;
  margin: 0 auto;
  padding: 2rem 2rem 2rem 2rem;
  background: #fff;
  margin-top: -5rem;
  border-radius: 5px;
`;

export const Content = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 0 0 0;

  button {
    font-size: 1rem;
    background: var(--light-yellow);
    border: 0;
    padding: 0 2rem;
    border-radius: 0.25rem;
    height: 3rem;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }

  div {
    margin-top: 2px;
    border: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
