/* eslint-disable react/no-array-index-key */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useRouteMatch } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { api } from '../../services/api';
import { authProvider } from '../../services/Auth/authProvider';

import { Container, Content } from './styles';

interface resultProps {
  total: number;
  key: string;
}

interface ResultParams {
  id: any;
}

export default function Result(): any {
  const { params } = useRouteMatch<ResultParams>();

  const [result, setResult] = useState<resultProps[]>([{ total: 0, key: '1' }]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadResult(): Promise<void> {
      await api
        .get(`/Reports/GetResults?reportid=${params.id}`, {
          headers: {
            Authorization: `Bearer ${authProvider.getAccountInfo()?.jwtIdToken
              }`,
          },
        })
        .then(response => {
          setResult(response.data);
          setLoading(false);
        });
    }
    loadResult();
  }, []);



  const sortResult = result.sort(
    (a: any, b: any) => b.total - a.total,
  );
  const labels = sortResult.map((e: any) => e.key);
  const total = sortResult.map((e: any) => e.total);

  const data = {
    labels,
    datasets: [
      {
        label: labels[0],
        data: total,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(201, 203, 207, 0.2)',
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)',
        ],
        borderWidth: 1,
      },
    ],
  };

  if (loading) {
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ReactLoading type="spin" color="#ffb81c" height="5%" width="5%" />
    </div>;
  }

  return (
    <>
      <Container>
        <Content>
          <h1>Result:</h1>
          <div>
            <h4>
              The highest score indicates your dominant style. The second
              highest score is a secondary or complimentary style. Begin to
              consider whether you think this categorization is accurate or
              useful.
            </h4>
          </div>
          <div>
            <Bar data={data} />
          </div>
          {result.map((fixValues: any, index) => (
            <div key={index}>
              {fixValues.key} - {fixValues.total}
            </div>
          ))}
          <a href="/">
            <button type="button">Exit</button>
          </a>
        </Content>
      </Container>
    </>
  );
}
